var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "90%",
        title: "Thêm mới học sinh",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "formAllTabStudent",
          attrs: {
            model: _vm.formAllTabStudent,
            rules: _vm.rules,
            "label-width": "130px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: { "tab-click": _vm.handleClickTab },
              model: {
                value: _vm.activeTabName,
                callback: function ($$v) {
                  _vm.activeTabName = $$v
                },
                expression: "activeTabName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "Thông tin", name: "mainInfo" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 40 } },
                    [
                      _c("el-col", { attrs: { span: 4 } }, [
                        _c("div", { staticClass: "grid-content" }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-upload",
                                {
                                  ref: "upload",
                                  staticClass: "avatar-uploader",
                                  class: { hideUpload: !_vm.showUpload },
                                  attrs: {
                                    "show-file-list": true,
                                    "list-type": "picture-card",
                                    "on-change": _vm.handleChangeUpload,
                                    "on-remove": _vm.handleRemove,
                                    "auto-upload": false,
                                    showPicutreInitial: "",
                                    limit: "1",
                                  },
                                },
                                [
                                  _c("span", [
                                    _c(
                                      "i",
                                      {
                                        staticClass: "el-icon-plus",
                                        staticStyle: { "font-size": "20px" },
                                      },
                                      [_vm._v("Thêm ảnh")]
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "group-radio-status",
                                  staticStyle: { "margin-top": "15px" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .kidStatus,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "kidStatus",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.kidStatus",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "kids-status",
                                      attrs: { label: "STUDYING" },
                                    },
                                    [_vm._v("Đang học")]
                                  ),
                                  _c(
                                    "el-radio",
                                    {
                                      staticClass: "kids-status",
                                      attrs: { label: "STUDY_WAIT" },
                                    },
                                    [_vm._v("Chờ học")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Chọn khối học",
                                  prop: "kidMainInfo.idGrade",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      placeholder: "Chọn khối học",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.selectGradeMethod()
                                      },
                                    },
                                    model: {
                                      value:
                                        _vm.formAllTabStudent.kidMainInfo
                                          .idGrade,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formAllTabStudent.kidMainInfo,
                                          "idGrade",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formAllTabStudent.kidMainInfo.idGrade",
                                    },
                                  },
                                  _vm._l(
                                    _vm.gradeOfSchoolList,
                                    function (item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          value: item.id,
                                          label: item.gradeName,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Chọn lớp học",
                                  prop: "kidMainInfo.idClass",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      filterable: "",
                                      clearable: "",
                                      placeholder: "Chọn lớp học",
                                    },
                                    model: {
                                      value:
                                        _vm.formAllTabStudent.kidMainInfo
                                          .idClass,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formAllTabStudent.kidMainInfo,
                                          "idClass",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formAllTabStudent.kidMainInfo.idClass",
                                    },
                                  },
                                  _vm._l(_vm.classOfGradeList, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label: item.className,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Họ tên",
                                  prop: "kidMainInfo.fullName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fullName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fullName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fullName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Biệt danh",
                                  prop: "kidMainInfo.nickName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .nickName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "nickName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.nickName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Người đại diện",
                                  prop: "kidMainInfo.representation",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.formAllTabStudent.kidMainInfo
                                          .representation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formAllTabStudent.kidMainInfo,
                                          "representation",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formAllTabStudent.kidMainInfo.representation",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Bố" } },
                                          [_vm._v("Bố")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-tooltip",
                                      {
                                        attrs: {
                                          effect: "dark",
                                          content:
                                            "Số điện thoại người đại diện là tài khoản ứng dụng OneKids và nhận tin nhắn SMS từ nhà trường",
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Mẹ" } },
                                          [_vm._v("Mẹ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Ngày sinh",
                                  prop: "kidMainInfo.birthDay",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    format: "dd-MM-yyyy",
                                    type: "date",
                                    "picker-options": _vm.pickerOptions,
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "Chọn ngày",
                                  },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .birthDay,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "birthDay",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.birthDay",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  prop: "kidMainInfo.gender",
                                  label: "Giới tính",
                                },
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value:
                                        _vm.formAllTabStudent.kidMainInfo
                                          .gender,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formAllTabStudent.kidMainInfo,
                                          "gender",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formAllTabStudent.kidMainInfo.gender",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "Nam" } },
                                      [_vm._v("Nam")]
                                    ),
                                    _c("el-radio", { attrs: { label: "Nữ" } }, [
                                      _vm._v("Nữ"),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Ngày nhập học",
                                  prop: "kidMainInfo.dateStart",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    format: "dd-MM-yyyy",
                                    type: "date",
                                    "value-format": "yyyy-MM-dd",
                                    placeholder: "Chọn ngày",
                                  },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .dateStart,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "dateStart",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.dateStart",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Địa chỉ hiện tại",
                                  prop: "kidMainInfo.address",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo.address,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "address",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.address",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Địa chỉ thường trú" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .permanentAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "permanentAddress",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.permanentAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Dân tộc" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo.ethnic,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "ethnic",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.ethnic",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "Số định danh" } },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .identificationNumber,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "identificationNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.identificationNumber",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Họ tên bố",
                                  prop: "kidMainInfo.fatherName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fatherName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fatherName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fatherName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Ngày sinh",
                                  prop: "kidMainInfo.fatherBirthday",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    format: "dd-MM-yyyy",
                                    "picker-options": _vm.pickerOptions,
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "Chọn ngày",
                                  },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fatherBirthday,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fatherBirthday",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fatherBirthday",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Số điện thoại",
                                  prop: "kidMainInfo.fatherPhone",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number", min: "0", max: "0" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fatherPhone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fatherPhone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fatherPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Email",
                                  prop: "kidMainInfo.fatherEmail",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fatherEmail,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fatherEmail",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fatherEmail",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Nghề nghiệp",
                                  prop: "kidMainInfo.fatherJob",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .fatherJob,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "fatherJob",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.fatherJob",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Họ tên mẹ",
                                  prop: "kidMainInfo.motherName",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .motherName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "motherName",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.motherName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Ngày sinh",
                                  prop: "kidMainInfo.motherBirthday",
                                },
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    format: "dd-MM-yyyy",
                                    "picker-options": _vm.pickerOptions,
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "Chọn ngày",
                                  },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .motherBirthday,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "motherBirthday",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.motherBirthday",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Số điện thoại",
                                  prop: "kidMainInfo.motherPhone",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "number", min: "0", max: "0" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .motherPhone,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "motherPhone",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.motherPhone",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Email",
                                  prop: "kidMainInfo.motherEmail",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .motherEmail,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "motherEmail",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.motherEmail",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Nghề nghiệp",
                                  prop: "kidMainInfo.motherJob",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { clearable: "" },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo
                                        .motherJob,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "motherJob",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.motherJob",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "Ghi chú",
                                  prop: "kidMainInfo.note",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", rows: 4 },
                                  model: {
                                    value:
                                      _vm.formAllTabStudent.kidMainInfo.note,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formAllTabStudent.kidMainInfo,
                                        "note",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formAllTabStudent.kidMainInfo.note",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "Sức khỏe chung", name: "extraInfo" } },
                [
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-left": "20%" },
                      attrs: { span: 15 },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Nhóm máu",
                            prop: "kidsExtraInfo.bloodType",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập nhóm máu",
                            },
                            model: {
                              value:
                                _vm.formAllTabStudent.kidsExtraInfo.bloodType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "bloodType",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.bloodType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Bơi", prop: "kidsExtraInfo.swim" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập bơi" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.swim,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "swim",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.swim",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Dị ứng",
                            prop: "kidsExtraInfo.allery",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập dị ứng",
                            },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.allery,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "allery",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.allery",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Ăn kiêng",
                            prop: "kidsExtraInfo.diet",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập ăn kiêng",
                            },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.diet,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "diet",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.diet",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Tai", prop: "kidsExtraInfo.ear" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập tai" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.ear,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "ear",
                                  $$v
                                )
                              },
                              expression: "formAllTabStudent.kidsExtraInfo.ear",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mũi", prop: "kidsExtraInfo.nose" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập mũi" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.nose,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "nose",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.nose",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Họng",
                            prop: "kidsExtraInfo.throat",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập họng" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.throat,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "throat",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.throat",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Da", prop: "kidsExtraInfo.skin" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập da" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.skin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "skin",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.skin",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "Mắt", prop: "kidsExtraInfo.eyes" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập mắt" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.eyes,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "eyes",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.eyes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "Tim", prop: "kidsExtraInfo.heart" },
                        },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "Nhập tim" },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.heart,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "heart",
                                  $$v
                                )
                              },
                              expression:
                                "formAllTabStudent.kidsExtraInfo.heart",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Béo phì",
                            prop: "kidsExtraInfo.fat",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: {
                              clearable: "",
                              placeholder: "Nhập béo phì",
                            },
                            model: {
                              value: _vm.formAllTabStudent.kidsExtraInfo.fat,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formAllTabStudent.kidsExtraInfo,
                                  "fat",
                                  $$v
                                )
                              },
                              expression: "formAllTabStudent.kidsExtraInfo.fat",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "App Onekids", name: "parentIcon" } },
                [
                  _c(
                    "el-table",
                    {
                      staticClass: "table-icon-1",
                      attrs: {
                        border: "",
                        data: _vm.parentIconAppCreate.parentIconCreate1,
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                      },
                    },
                    [
                      _c("el-table-column", { attrs: { type: "index" } }),
                      _c("el-table-column", {
                        attrs: { prop: "iconName", label: "Chức năng" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Hiển thị", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  model: {
                                    value: scope.row.statusShow,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "statusShow", $$v)
                                    },
                                    expression: "scope.row.statusShow",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Mở khóa", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.rootLockStatus
                                  ? _c("el-checkbox", {
                                      model: {
                                        value: scope.row.status,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "status", $$v)
                                        },
                                        expression: "scope.row.status",
                                      },
                                    })
                                  : _c("el-checkbox", {
                                      attrs: { disabled: "" },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      staticClass: "table-icon-2",
                      attrs: {
                        border: "",
                        data: _vm.parentIconAppCreate.parentIconCreate2,
                        "highlight-current-row": "",
                        "header-cell-style": _vm.tableHeaderColor,
                      },
                    },
                    [
                      _c("el-table-column", { attrs: { type: "index" } }),
                      _c("el-table-column", {
                        attrs: { prop: "iconName", label: "Chức năng" },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Hiển thị", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("el-checkbox", {
                                  model: {
                                    value: scope.row.statusShow,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "statusShow", $$v)
                                    },
                                    expression: "scope.row.statusShow",
                                  },
                                }),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Mở khóa", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                scope.row.rootLockStatus
                                  ? _c("el-checkbox", {
                                      model: {
                                        value: scope.row.status,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "status", $$v)
                                        },
                                        expression: "scope.row.status",
                                      },
                                    })
                                  : _c("el-checkbox", {
                                      attrs: { disabled: "" },
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "tab-infor-button" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialogByButton("formAllTabStudent")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v("Đóng")]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formAllTabStudent")
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v("Lưu")]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }