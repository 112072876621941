var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Cài đặt chế độ xem cho Camera",
        visible: _vm.showSettingCamera,
        width: "900px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
        top: "3vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showSettingCamera = $event
        },
        open: _vm.initialDataMethod,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "160px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Giới hạn xem liên tục" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.dataInput.viewLimitStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "viewLimitStatus", $$v)
                    },
                    expression: "dataInput.viewLimitStatus",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("Có")]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("Không"),
                  ]),
                ],
                1
              ),
              _c(
                "span",
                { staticStyle: { float: "right" } },
                [
                  _vm._v("Số phút xem liên tục     \n        "),
                  _c("el-input-number", {
                    staticStyle: { width: "250px" },
                    model: {
                      value: _vm.dataInput.viewLimitNumber,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "viewLimitNumber", $$v)
                      },
                      expression: "dataInput.viewLimitNumber",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "viewLimitText" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.viewLimitText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "viewLimitText", $$v)
                  },
                  expression: "dataInput.viewLimitText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Khoảng thời gian xem" } },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.dataInput.timeViewStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "timeViewStatus", $$v)
                    },
                    expression: "dataInput.timeViewStatus",
                  },
                },
                [_vm._v("Giới hạn khoảng thời gian xem camera không\n      ")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Thời gian được xem" } },
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 1"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                            clearable: false,
                          },
                          model: {
                            value: _vm.dataInput.startTime1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime1", $$v)
                            },
                            expression: "dataInput.startTime1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                            clearable: false,
                          },
                          model: {
                            value: _vm.dataInput.endTime1,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime1", $$v)
                            },
                            expression: "dataInput.endTime1",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 2"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime2", $$v)
                            },
                            expression: "dataInput.startTime2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime2,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime2", $$v)
                            },
                            expression: "dataInput.endTime2",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 3"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime3", $$v)
                            },
                            expression: "dataInput.startTime3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime3,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime3", $$v)
                            },
                            expression: "dataInput.endTime3",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 4"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime4,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime4", $$v)
                            },
                            expression: "dataInput.startTime4",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime4,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime4", $$v)
                            },
                            expression: "dataInput.endTime4",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", { staticClass: "grid-content bg-purple" }, [
                      _vm._v("Giá trị 5"),
                    ]),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Bắt đầu",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.startTime5,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "startTime5", $$v)
                            },
                            expression: "dataInput.startTime5",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { span: 8 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content bg-purple" },
                      [
                        _c("el-time-select", {
                          staticStyle: { width: "170px" },
                          attrs: {
                            placeholder: "Kết thúc",
                            "picker-options": _vm.getTimeSelect(),
                          },
                          model: {
                            value: _vm.dataInput.endTime5,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "endTime5", $$v)
                            },
                            expression: "dataInput.endTime5",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "timeViewText" } },
            [
              _c("el-input", {
                attrs: { placeholder: "Nhập nội dung" },
                model: {
                  value: _vm.dataInput.timeViewText,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "timeViewText", $$v)
                  },
                  expression: "dataInput.timeViewText",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Chọn ngày trong tuần" } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-checkbox",
                    {
                      on: { change: _vm.handelChangeStatusDayOfWeek },
                      model: {
                        value: _vm.dataInput.statusDayOfWeek,
                        callback: function ($$v) {
                          _vm.$set(_vm.dataInput, "statusDayOfWeek", $$v)
                        },
                        expression: "dataInput.statusDayOfWeek",
                      },
                    },
                    [
                      _vm._v(
                        "Giới hạn khoảng thời gian xem camera các ngày trong tuần không\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm.dataInput.statusDayOfWeek
                ? _c(
                    "el-row",
                    [
                      _vm.dataInput.statusDayOfWeek
                        ? _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.handleSelectAllDayOfWeek },
                              model: {
                                value: _vm.selectAllDayOfWeek,
                                callback: function ($$v) {
                                  _vm.selectAllDayOfWeek = $$v
                                },
                                expression: "selectAllDayOfWeek",
                              },
                            },
                            [_vm._v("Tất cả\n        ")]
                          )
                        : _vm._e(),
                      _vm.dataInput.statusDayOfWeek
                        ? _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.handleSelectDayOfWeek },
                              model: {
                                value: _vm.selectDayOfWeek,
                                callback: function ($$v) {
                                  _vm.selectDayOfWeek = $$v
                                },
                                expression: "selectDayOfWeek",
                              },
                            },
                            [_vm._v("Chọn từng ngày trong tuần\n        ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.dataInput.statusDayOfWeek
            ? _c(
                "el-form-item",
                { attrs: { label: "Ngày được xem" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusMonday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusMonday", $$v)
                            },
                            expression: "dataInput.statusMonday",
                          },
                        },
                        [_vm._v("Thứ 2\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusTuesday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusTuesday", $$v)
                            },
                            expression: "dataInput.statusTuesday",
                          },
                        },
                        [_vm._v("Thứ 3\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusWednesday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusWednesday", $$v)
                            },
                            expression: "dataInput.statusWednesday",
                          },
                        },
                        [_vm._v("Thứ 4\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusThursday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusThursday", $$v)
                            },
                            expression: "dataInput.statusThursday",
                          },
                        },
                        [_vm._v("Thứ 5\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusFriday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusFriday", $$v)
                            },
                            expression: "dataInput.statusFriday",
                          },
                        },
                        [_vm._v("Thứ 6\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusSaturday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusSaturday", $$v)
                            },
                            expression: "dataInput.statusSaturday",
                          },
                        },
                        [_vm._v("Thứ 7\n        ")]
                      ),
                      _c(
                        "el-checkbox",
                        {
                          on: { change: _vm.handleChangeDayOfWeek },
                          model: {
                            value: _vm.dataInput.statusSunday,
                            callback: function ($$v) {
                              _vm.$set(_vm.dataInput, "statusSunday", $$v)
                            },
                            expression: "dataInput.statusSunday",
                          },
                        },
                        [_vm._v("Chủ nhật\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.dataInput.statusDayOfWeek
            ? _c(
                "el-form-item",
                { attrs: { prop: "timeViewDayOfWeekText" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Nhập nội dung" },
                    model: {
                      value: _vm.dataInput.timeViewDayOfWeekText,
                      callback: function ($$v) {
                        _vm.$set(_vm.dataInput, "timeViewDayOfWeekText", $$v)
                      },
                      expression: "dataInput.timeViewDayOfWeekText",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }