var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Thêm bộ lọc cho học sinh",
        visible: _vm.dialogVisible,
        width: "550px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "dataInput",
          attrs: {
            "label-width": "120px",
            model: _vm.dataInput,
            "label-position": "left",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Chọn loại", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  model: {
                    value: _vm.dataInput.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.dataInput, "type", $$v)
                    },
                    expression: "dataInput.type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "Ngày nhập học", value: "startDate" },
                  }),
                  _c("el-option", {
                    attrs: { label: "Ngày sinh", value: "birthday" },
                  }),
                  _vm.status === "LEAVE_SCHOOL"
                    ? _c("el-option", {
                        attrs: { label: "Ngày nghỉ học", value: "leaveSchool" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Khoảng ngày", prop: "dateList" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  type: "daterange",
                  "unlink-panels": "",
                  "range-separator": "-",
                  "start-placeholder": "Ngày bắt đầu",
                  "end-placeholder": "Ngày kết thúc",
                  "picker-options": _vm.pickerOptions,
                },
                model: {
                  value: _vm.dataInput.dateList,
                  callback: function ($$v) {
                    _vm.$set(_vm.dataInput, "dateList", $$v)
                  },
                  expression: "dataInput.dateList",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.OK")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }