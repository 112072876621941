var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "Xuất đơn thuốc",
        visible: _vm.dialogVisible,
        width: "600px",
        "before-close": _vm.closeDialog,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-radio-group",
        {
          model: {
            value: _vm.dataType,
            callback: function ($$v) {
              _vm.dataType = $$v
            },
            expression: "dataType",
          },
        },
        [
          _c(
            "el-radio",
            {
              staticStyle: { "margin-right": "150px" },
              attrs: { label: "allType" },
            },
            [_vm._v("Tất cả đơn thuốc")]
          ),
          _c("el-radio", { attrs: { label: "dateType" } }, [
            _vm._v("Chọn ngày"),
          ]),
          _vm.dataType == "dateType"
            ? _c("el-date-picker", {
                staticStyle: { width: "30%" },
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  format: "dd-MM-yyyy",
                  clearable: false,
                },
                model: {
                  value: _vm.dateSelect,
                  callback: function ($$v) {
                    _vm.dateSelect = $$v
                  },
                  expression: "dateSelect",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "danger", size: "medium" },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-close" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.close")))]),
            ]
          ),
          _c(
            "el-button",
            {
              attrs: {
                type: "success",
                size: "medium",
                loading: _vm.loadingButton,
                mini: "",
              },
              on: {
                click: function ($event) {
                  return _vm.submitForm()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-circle-check" }),
              _c("span", [_vm._v(_vm._s(_vm.$t("button.save")))]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }