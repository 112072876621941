var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            title: _vm.showTitle(),
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAllTabStudent",
              attrs: {
                model: _vm.formAllTabStudent,
                rules: _vm.rules,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClickTab },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "commonInfor" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _vm.formAllTabStudent.kidMainInfo.avatarKid !=
                                  null && _vm.showPicutreInitial
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "150px",
                                        float: "left",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: {
                                        src: _vm.formAllTabStudent.kidMainInfo
                                          .avatarKid,
                                        fit: "fill",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "avatar-uploader",
                                    class: { hideUpload: !_vm.showUpload },
                                    attrs: {
                                      disabled: _vm.showDetail,
                                      action: "",
                                      "show-file-list": true,
                                      "list-type": "picture-card",
                                      "on-change": _vm.handleChangeUpload,
                                      "on-remove": _vm.handleRemove,
                                      "auto-upload": false,
                                      limit: 1,
                                    },
                                  },
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .avatarKid != null
                                      ? _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Cập nhật ảnh")]
                                          ),
                                        ])
                                      : _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Thêm ảnh")]
                                          ),
                                        ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      width: "150px",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "show-code",
                                      staticStyle: { color: "red" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .kidCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "kidCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.kidCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "group-radio-status",
                                        staticStyle: { "margin-top": "15px" },
                                        attrs: { disabled: _vm.showDetail },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeKidsStatus()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .kidStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "kidStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.kidStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDYING" },
                                          },
                                          [
                                            _vm._v(
                                              "Đang học\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDY_WAIT" },
                                          },
                                          [
                                            _vm._v(
                                              "Chờ học\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "RESERVE" },
                                          },
                                          [
                                            _vm._v(
                                              "Bảo lưu\n                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "LEAVE_SCHOOL" },
                                          },
                                          [
                                            _vm._v(
                                              "Nghỉ học\n                    "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "RESERVE"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateRetain",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày bảo lưu",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateRetain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateRetain",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateRetain",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "LEAVE_SCHOOL"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateLeave",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày nghỉ học",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateLeave,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateLeave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateLeave",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Khối học",
                                      prop: "kidMainInfo.gradeName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .gradeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "gradeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.gradeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Lớp học",
                                      prop: "kidMainInfo.className",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .className,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "className",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.className",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên",
                                      prop: "kidMainInfo.fullName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fullName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fullName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Biệt danh",
                                      prop: "kidMainInfo.nickName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .nickName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "nickName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.nickName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Người đại diện",
                                      prop: "kidMainInfo.representation",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .representation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "representation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.representation",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Bố" } },
                                          [_vm._v("Bố")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Mẹ" } },
                                          [_vm._v("Mẹ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.birthDay",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .birthDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "birthDay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.birthDay",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "kidMainInfo.gender",
                                      label: "Giới tính",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.gender",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nam" } },
                                          [_vm._v("Nam")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nữ" } },
                                          [_vm._v("Nữ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày nhập học",
                                      prop: "kidMainInfo.dateStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "dateStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ hiện tại",
                                      prop: "kidMainInfo.address",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ thường trú",
                                      prop: "kidMainInfo.permanentAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .permanentAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "permanentAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.permanentAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dân tộc",
                                      prop: "kidMainInfo.ethnic",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .ethnic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "ethnic",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.ethnic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số định danh",
                                      prop: "kidMainInfo.identificationNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .identificationNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "identificationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.identificationNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên bố",
                                      prop: "kidMainInfo.fatherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.fatherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.fatherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.fatherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.fatherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên mẹ",
                                      prop: "kidMainInfo.motherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.motherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        "picker-options": _vm.pickerOptions,
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.motherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.motherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.motherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ghi chú",
                                      prop: "kidMainInfo.note",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "textarea",
                                        rows: 4,
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .note,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "note",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.note",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Sức khỏe chung", name: "extraInfo" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20%" },
                          attrs: { span: 15 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nhóm máu", prop: "bloodType" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập nhóm máu",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.bloodType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "bloodType",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.bloodType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Bơi", prop: "swim" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập bơi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.swim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "swim",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.swim",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Dị ứng", prop: "allery" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập dị ứng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.allery,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "allery",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.allery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ăn kiêng", prop: "diet" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập ăn kiêng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.diet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "diet",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.diet",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tai", prop: "ear" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tai",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.ear,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "ear",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.ear",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mũi", prop: "nose" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mũi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.nose,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "nose",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.nose",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Họng", prop: "throat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập họng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.throat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "throat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.throat",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Da", prop: "skin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập da",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.skin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "skin",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.skin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mắt", prop: "eyes" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mắt",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.eyes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "eyes",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.eyes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tim", prop: "heart" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tim",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.heart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "heart",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.heart",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Béo phì", prop: "fat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập béo phì",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.fat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "fat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.fat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "App Onekids", name: "appOnekids" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-1",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate1,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-2",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate2,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showButtonClick
            ? _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            title: _vm.showTitle(),
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAllTabStudent",
              attrs: {
                model: _vm.formAllTabStudent,
                rules: _vm.rules,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClickTab },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "commonInfor" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _vm.formAllTabStudent.kidMainInfo.avatarKid !=
                                  null && _vm.showPicutreInitial
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "150px",
                                        float: "left",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: {
                                        src: _vm.formAllTabStudent.kidMainInfo
                                          .avatarKid,
                                        fit: "fill",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "avatar-uploader",
                                    class: { hideUpload: !_vm.showUpload },
                                    attrs: {
                                      disabled: _vm.showDetail,
                                      action: "",
                                      "show-file-list": true,
                                      "list-type": "picture-card",
                                      "on-change": _vm.handleChangeUpload,
                                      "on-remove": _vm.handleRemove,
                                      "auto-upload": false,
                                      limit: 1,
                                    },
                                  },
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .avatarKid != null
                                      ? _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Cập nhật ảnh")]
                                          ),
                                        ])
                                      : _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Thêm ảnh")]
                                          ),
                                        ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      width: "150px",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "show-code",
                                      staticStyle: { color: "red" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .kidCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "kidCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.kidCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "group-radio-status",
                                        staticStyle: { "margin-top": "15px" },
                                        attrs: { disabled: _vm.showDetail },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeKidsStatus()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .kidStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "kidStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.kidStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDYING" },
                                          },
                                          [
                                            _vm._v(
                                              "Đang học\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDY_WAIT" },
                                          },
                                          [
                                            _vm._v(
                                              "Chờ học\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "RESERVE" },
                                          },
                                          [
                                            _vm._v(
                                              "Bảo lưu\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "LEAVE_SCHOOL" },
                                          },
                                          [
                                            _vm._v(
                                              "Nghỉ học\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "RESERVE"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateRetain",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày bảo lưu",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateRetain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateRetain",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateRetain",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "LEAVE_SCHOOL"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateLeave",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày nghỉ học",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateLeave,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateLeave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateLeave",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Khối học",
                                      prop: "kidMainInfo.gradeName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .gradeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "gradeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.gradeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Lớp học",
                                      prop: "kidMainInfo.className",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .className,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "className",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.className",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên",
                                      prop: "kidMainInfo.fullName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fullName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fullName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Biệt danh",
                                      prop: "kidMainInfo.nickName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .nickName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "nickName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.nickName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Người đại diện",
                                      prop: "kidMainInfo.representation",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .representation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "representation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.representation",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Bố" } },
                                          [_vm._v("Bố")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Mẹ" } },
                                          [_vm._v("Mẹ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.birthDay",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .birthDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "birthDay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.birthDay",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "kidMainInfo.gender",
                                      label: "Giới tính",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.gender",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nam" } },
                                          [_vm._v("Nam")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nữ" } },
                                          [_vm._v("Nữ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày nhập học",
                                      prop: "kidMainInfo.dateStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "dateStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ hiện tại",
                                      prop: "kidMainInfo.address",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ thường trú",
                                      prop: "kidMainInfo.permanentAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .permanentAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "permanentAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.permanentAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dân tộc",
                                      prop: "kidMainInfo.ethnic",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .ethnic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "ethnic",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.ethnic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số định danh",
                                      prop: "kidMainInfo.identificationNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .identificationNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "identificationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.identificationNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên bố",
                                      prop: "kidMainInfo.fatherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.fatherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.fatherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.fatherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.fatherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên mẹ",
                                      prop: "kidMainInfo.motherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.motherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        "picker-options": _vm.pickerOptions,
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.motherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.motherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.motherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ghi chú",
                                      prop: "kidMainInfo.note",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "textarea",
                                        rows: 4,
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .note,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "note",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.note",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Sức khỏe chung", name: "extraInfo" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20%" },
                          attrs: { span: 15 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nhóm máu", prop: "bloodType" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập nhóm máu",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.bloodType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "bloodType",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.bloodType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Bơi", prop: "swim" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập bơi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.swim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "swim",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.swim",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Dị ứng", prop: "allery" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập dị ứng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.allery,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "allery",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.allery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ăn kiêng", prop: "diet" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập ăn kiêng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.diet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "diet",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.diet",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tai", prop: "ear" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tai",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.ear,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "ear",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.ear",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mũi", prop: "nose" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mũi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.nose,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "nose",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.nose",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Họng", prop: "throat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập họng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.throat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "throat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.throat",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Da", prop: "skin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập da",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.skin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "skin",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.skin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mắt", prop: "eyes" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mắt",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.eyes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "eyes",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.eyes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tim", prop: "heart" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tim",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.heart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "heart",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.heart",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Béo phì", prop: "fat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập béo phì",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.fat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "fat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.fat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "App Onekids", name: "appOnekids" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-1",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate1,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-2",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate2,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showButtonClick
            ? _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            width: "90%",
            title: _vm.showTitle(),
            "before-close": _vm.closeDialog,
            "close-on-click-modal": false,
            top: "3vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formAllTabStudent",
              attrs: {
                model: _vm.formAllTabStudent,
                rules: _vm.rules,
                "label-width": "130px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClickTab },
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Thông tin", name: "commonInfor" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 40 } },
                        [
                          _c("el-col", { attrs: { span: 4 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _vm.formAllTabStudent.kidMainInfo.avatarKid !=
                                  null && _vm.showPicutreInitial
                                  ? _c("el-image", {
                                      staticStyle: {
                                        width: "150px",
                                        height: "150px",
                                        float: "left",
                                        "margin-bottom": "10px",
                                      },
                                      attrs: {
                                        src: _vm.formAllTabStudent.kidMainInfo
                                          .avatarKid,
                                        fit: "fill",
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-upload",
                                  {
                                    ref: "upload",
                                    staticClass: "avatar-uploader",
                                    class: { hideUpload: !_vm.showUpload },
                                    attrs: {
                                      disabled: _vm.showDetail,
                                      action: "",
                                      "show-file-list": true,
                                      "list-type": "picture-card",
                                      "on-change": _vm.handleChangeUpload,
                                      "on-remove": _vm.handleRemove,
                                      "auto-upload": false,
                                      limit: 1,
                                    },
                                  },
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .avatarKid != null
                                      ? _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Cập nhật ảnh")]
                                          ),
                                        ])
                                      : _c("span", [
                                          _c(
                                            "i",
                                            {
                                              staticClass: "el-icon-plus",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            },
                                            [_vm._v("Thêm ảnh")]
                                          ),
                                        ]),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "margin-top": "10px",
                                      width: "150px",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      staticClass: "show-code",
                                      staticStyle: { color: "red" },
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .kidCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "kidCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.kidCode",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        staticClass: "group-radio-status",
                                        staticStyle: { "margin-top": "15px" },
                                        attrs: { disabled: _vm.showDetail },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeKidsStatus()
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .kidStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "kidStatus",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.kidStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDYING" },
                                          },
                                          [
                                            _vm._v(
                                              "Đang học\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "STUDY_WAIT" },
                                          },
                                          [
                                            _vm._v(
                                              "Chờ học\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "RESERVE" },
                                          },
                                          [
                                            _vm._v(
                                              "Bảo lưu\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "el-radio",
                                          {
                                            staticClass: "kids-status",
                                            attrs: { label: "LEAVE_SCHOOL" },
                                          },
                                          [
                                            _vm._v(
                                              "Nghỉ học\n                  "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "RESERVE"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateRetain",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày bảo lưu",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateRetain,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateRetain",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateRetain",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.formAllTabStudent.kidMainInfo
                                      .kidStatus == "LEAVE_SCHOOL"
                                      ? _c(
                                          "el-form-item",
                                          {
                                            staticStyle: {
                                              "margin-left": "-120px",
                                            },
                                            attrs: {
                                              prop: "kidMainInfo.dateLeave",
                                            },
                                          },
                                          [
                                            _c("el-date-picker", {
                                              staticStyle: { width: "160px" },
                                              attrs: {
                                                type: "date",
                                                format: "dd-MM-yyyy",
                                                "value-format": "yyyy-MM-dd",
                                                placeholder: "Ngày nghỉ học",
                                                disabled: _vm.showDateStatus,
                                              },
                                              model: {
                                                value:
                                                  _vm.formAllTabStudent
                                                    .kidMainInfo.dateLeave,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.formAllTabStudent
                                                      .kidMainInfo,
                                                    "dateLeave",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formAllTabStudent.kidMainInfo.dateLeave",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Khối học",
                                      prop: "kidMainInfo.gradeName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .gradeName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "gradeName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.gradeName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Lớp học",
                                      prop: "kidMainInfo.className",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: { disabled: "" },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .className,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "className",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.className",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên",
                                      prop: "kidMainInfo.fullName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fullName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fullName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fullName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Biệt danh",
                                      prop: "kidMainInfo.nickName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .nickName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "nickName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.nickName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Người đại diện",
                                      prop: "kidMainInfo.representation",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .representation,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "representation",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.representation",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Bố" } },
                                          [_vm._v("Bố")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Mẹ" } },
                                          [_vm._v("Mẹ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.birthDay",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .birthDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "birthDay",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.birthDay",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      prop: "kidMainInfo.gender",
                                      label: "Giới tính",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { disabled: _vm.showDetail },
                                        model: {
                                          value:
                                            _vm.formAllTabStudent.kidMainInfo
                                              .gender,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formAllTabStudent.kidMainInfo,
                                              "gender",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "formAllTabStudent.kidMainInfo.gender",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nam" } },
                                          [_vm._v("Nam")]
                                        ),
                                        _c(
                                          "el-radio",
                                          { attrs: { label: "Nữ" } },
                                          [_vm._v("Nữ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày nhập học",
                                      prop: "kidMainInfo.dateStart",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .dateStart,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "dateStart",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.dateStart",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ hiện tại",
                                      prop: "kidMainInfo.address",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .address,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "address",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.address",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Địa chỉ thường trú",
                                      prop: "kidMainInfo.permanentAddress",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .permanentAddress,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "permanentAddress",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.permanentAddress",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Dân tộc",
                                      prop: "kidMainInfo.ethnic",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .ethnic,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "ethnic",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.ethnic",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số định danh",
                                      prop: "kidMainInfo.identificationNumber",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .identificationNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "identificationNumber",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.identificationNumber",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                          _c("el-col", { attrs: { span: 10 } }, [
                            _c(
                              "div",
                              { staticClass: "grid-content" },
                              [
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên bố",
                                      prop: "kidMainInfo.fatherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.fatherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "picker-options": _vm.pickerOptions,
                                        "value-format": "yyyy-MM-dd",
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.fatherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.fatherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.fatherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .fatherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "fatherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.fatherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Họ tên mẹ",
                                      prop: "kidMainInfo.motherName",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherName,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherName",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherName",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ngày sinh",
                                      prop: "kidMainInfo.motherBirthday",
                                    },
                                  },
                                  [
                                    _c("el-date-picker", {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        format: "dd-MM-yyyy",
                                        "value-format": "yyyy-MM-dd",
                                        "picker-options": _vm.pickerOptions,
                                        type: "date",
                                        placeholder: "Chọn ngày",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherBirthday,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherBirthday",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherBirthday",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Số điện thoại",
                                      prop: "kidMainInfo.motherPhone",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "number",
                                        min: "0",
                                        max: "0",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherPhone,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherPhone",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherPhone",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Email",
                                      prop: "kidMainInfo.motherEmail",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherEmail,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherEmail",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherEmail",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Nghề nghiệp",
                                      prop: "kidMainInfo.motherJob",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        clearable: "",
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .motherJob,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "motherJob",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.motherJob",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "Ghi chú",
                                      prop: "kidMainInfo.note",
                                    },
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        disabled: _vm.showDetail,
                                        type: "textarea",
                                        rows: 4,
                                      },
                                      model: {
                                        value:
                                          _vm.formAllTabStudent.kidMainInfo
                                            .note,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.formAllTabStudent.kidMainInfo,
                                            "note",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "formAllTabStudent.kidMainInfo.note",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Sức khỏe chung", name: "extraInfo" } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-left": "20%" },
                          attrs: { span: 15 },
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "Nhóm máu", prop: "bloodType" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập nhóm máu",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.bloodType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "bloodType",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.bloodType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Bơi", prop: "swim" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập bơi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.swim,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "swim",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.swim",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Dị ứng", prop: "allery" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập dị ứng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.allery,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "allery",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.allery",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Ăn kiêng", prop: "diet" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập ăn kiêng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.diet,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "diet",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.diet",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tai", prop: "ear" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tai",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.ear,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "ear",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.ear",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mũi", prop: "nose" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mũi",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.nose,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "nose",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.nose",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Họng", prop: "throat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập họng",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.throat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "throat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.throat",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Da", prop: "skin" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập da",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.skin,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "skin",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.skin",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Mắt", prop: "eyes" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập mắt",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.eyes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "eyes",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.eyes",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Tim", prop: "heart" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập tim",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.heart,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "heart",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.heart",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "Béo phì", prop: "fat" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: _vm.showDetail,
                                  clearable: "",
                                  placeholder: "Nhập béo phì",
                                },
                                model: {
                                  value: _vm.kidsExtraInfoUpdate.fat,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.kidsExtraInfoUpdate,
                                      "fat",
                                      $$v
                                    )
                                  },
                                  expression: "kidsExtraInfoUpdate.fat",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "App Onekids", name: "appOnekids" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-1",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate1,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          staticClass: "table-icon-2",
                          attrs: {
                            border: "",
                            data: _vm.parentIconAppUpdate.parentIconUpdate2,
                            "highlight-current-row": "",
                            "header-cell-style": _vm.tableHeaderColor,
                            "max-height": _vm.$tableMaxHeight,
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              type: "index",
                              label: "STT",
                              align: "center",
                              width: "50",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "iconName", label: "Chức năng" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Hiển thị",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-checkbox", {
                                      attrs: { disabled: _vm.showDetail },
                                      model: {
                                        value: scope.row.statusShow,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "statusShow", $$v)
                                        },
                                        expression: "scope.row.statusShow",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "Mở khóa",
                              align: "center",
                              width: "80",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    scope.row.rootLockStatus
                                      ? _c("el-checkbox", {
                                          attrs: { disabled: _vm.showDetail },
                                          model: {
                                            value: scope.row.status,
                                            callback: function ($$v) {
                                              _vm.$set(scope.row, "status", $$v)
                                            },
                                            expression: "scope.row.status",
                                          },
                                        })
                                      : _c("el-checkbox", {
                                          attrs: { disabled: "" },
                                        }),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  this.appType === "plus"
                    ? _c(
                        "el-tab-pane",
                        {
                          attrs: {
                            label: "Cấu hình Cam",
                            name: "commonConfig",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 5, offset: 7 } },
                                [
                                  _c("el-checkbox", {
                                    attrs: { disabled: _vm.showDetail },
                                    model: {
                                      value:
                                        _vm.formAllTabStudent.kidMainInfo
                                          .oneCamStatus,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.formAllTabStudent.kidMainInfo,
                                          "oneCamStatus",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "formAllTabStudent.kidMainInfo.oneCamStatus",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _vm._v(
                                        "Cho phép xem Camera ở OneCamPlus không?"
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { offset: 7 } },
                                [
                                  _c(
                                    "el-table",
                                    {
                                      ref: "multipleTable",
                                      staticStyle: {
                                        width: "700px",
                                        "margin-top": "10px",
                                      },
                                      attrs: {
                                        "element-loading-spinner":
                                          "el-icon-loading",
                                        "element-loading-background":
                                          "rgba(255,255,255, 0)",
                                        data: _vm.kidCamList,
                                        "highlight-current-row": "",
                                        "header-cell-style":
                                          _vm.tableHeaderColor,
                                        border: "",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          type: "index",
                                          label: "STT",
                                          width: "50",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "cameraName",
                                          label: "Tên cam",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          prop: "active",
                                          label: "Trạng thái",
                                          width: "100px",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-checkbox", {
                                                    staticStyle: {
                                                      "margin-left": "10px",
                                                    },
                                                    attrs: {
                                                      disabled:
                                                        scope.row.existClass ||
                                                        _vm.showDetail,
                                                    },
                                                    model: {
                                                      value: scope.row.active,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "active",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.active",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          4043553361
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "Tác vụ",
                                          width: "120",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        disabled:
                                                          scope.row
                                                            .existClass ||
                                                          !scope.row.active ||
                                                          _vm.showDetail,
                                                        size: "mini",
                                                        type: "success",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleSettingCame(
                                                            scope.row.idCamera,
                                                            scope.row.cameraName
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Thiết lập\n                  "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3253342680
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm.showButtonClick
            ? _c(
                "div",
                { staticClass: "tab-infor-button" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "danger", size: "medium" },
                      on: {
                        click: function ($event) {
                          return _vm.closeDialogByButton("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-close" }),
                      _c("span", [_vm._v("Đóng")]),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "success",
                        size: "medium",
                        loading: _vm.loadingButton,
                        mini: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("formAllTabStudent")
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-circle-check" }),
                      _c("span", [_vm._v("Lưu")]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("CamSettingStudentDialog", {
        attrs: {
          showSettingCamera: _vm.showSettingCamera,
          idKids: _vm.idKids,
          idCamera: _vm.idCamera,
          nameCamera: _vm.nameCamera,
        },
        on: { close: _vm.handleCloseSettingCameraDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }